<template>
<div>
    <Header /> 

    <!-- content main --> 
    <div class="main-body hta_roadmap fiche-dec" style="padding: 0px; height:calc(100vh - 130px); position: relative !important;">
        <div class="body-fiche w-100">
            <div class="wrapper-box">
                <div class="header-title">
                    <div><img :src="require('@/assets/images/flag/new/'+$route.params.country+'.svg')" alt=""> HTA roadmap {{country_name}}</div>
                    <div>
                        <router-link to="/hta_roadmap/fr" v-if="$route.params.country !== 'fr'"><img src="@/assets/images/flag/new/fr.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/gb" v-if="$route.params.country !== 'gb'"><img src="@/assets/images/flag/new/gb.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/de" v-if="$route.params.country !== 'de'"><img src="@/assets/images/flag/new/de.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/es" v-if="$route.params.country !== 'es'"><img src="@/assets/images/flag/new/es.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/it" v-if="$route.params.country !== 'it'"><img src="@/assets/images/flag/new/it.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/au" v-if="$route.params.country !== 'au'"><img src="@/assets/images/flag/new/au.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/cn" v-if="$route.params.country !== 'cn'"><img src="@/assets/images/flag/new/cn.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/ie" v-if="$route.params.country !== 'ie'"><img src="@/assets/images/flag/new/ie.svg" alt=""> </router-link>
                        <router-link to="/hta_roadmap/ca" v-if="$route.params.country !== 'ca'"><img src="@/assets/images/flag/new/ca.svg" alt=""> </router-link>
                    </div>
                </div>

                <!-- Nav tabs -->
                <ul class="nav nav-tabs nav-tabs-modify" role="tablist" v-if="datas && datas.data">
                    <li class="nav-item" role="presentation" v-for="(value, key) in datas.data" :key="key">
                        <button :class="'nav-link tab-'+class_(value) + ' ' + (key == 0 ? 'active' : '')" :id="tab(value)+'-tab'" data-bs-toggle="tab"
                                :data-bs-target="'#tab-'+tab(value)"
                                type="button" role="tab" aria-controls="contact" aria-selected="false">
                            <span></span>
                            <div class="box-icon">
                                <img v-if="value.url_fr === 'time-to-access' " :src="require('@/assets/images/icon/icon-time-to-access.svg')" alt="">
                                <img v-else :src="require('@/assets/images/icon/icon-'+img(value)+'.png')" alt="">
                            </div>
                            {{value['title']}}
                        </button>
                    </li>
                </ul>

                <!-- Tab content -->
                <div role="tab-content" id="myTabContent" v-if="datas && datas.data">
                    <div :class="'tab-pane fade '+(key == 0 ? 'active show' : '')" :id="'tab-'+tab(value)" role="tabpanel" :aria-labelledby="tab(value)+'-tab'" v-for="(value, key) in datas.data" :key="'data-'+tab(value)+'_'+key">
                        <div class="wrapper-table" v-if="class_(value)">
                            <div class="table-responsive" >
                                <table v-for="(value_multi, key_multi) in value.content_multis" :key="'key_multi'+key_multi" :class="'table-'+class_(value)">

                                    <template v-if="value_multi['is_full_html']">
                                        <tr>
                                            <td class="tb-first" rowspan="4">
                                                <div class="box-title" v-html="value_multi['title']"></div>
                                            </td>
                                            <td>
                                                <div v-html="value_multi['content']"></div>                                                
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <template v-for="i in 6">
                                            <tr v-if="i == 1" :key="'tile_'+key_multi+'_'+key+'_'+(i-1)">
                                                <td class="tb-first" rowspan="4">
                                                    <div class="box-title" v-html="value_multi['title']"> </div>
                                                </td>
                                            </tr>
                                            <tr v-else-if="value_multi['title_'+(i-1)]" :key="'tile_'+key_multi+'_'+key+'_'+(i-1)">
                                                <td class="tb-second" :style="value_multi['title_'+(i-1)] != 'WHAT' && value_multi['title_'+(i-1)] != 'WHO' && value_multi['title_'+(i-1)] != 'HOW' ? 'color:black; width: 120px; font-weight: 100px;' : ''">
                                                    <span v-html="value_multi['title_'+(i-1)]"> </span>
                                                </td>
                                                <td>
                                                    <span v-html="value_multi['content_'+(i-1)].replaceAll('../../../tinymcefilemanager', '/tinymcefilemanager').replaceAll('onclick', 'id').replaceAll('();', '').replaceAll('/dist/2019/images/icon/Table es', '/tinymcefilemanager/image')"></span>
                                                </td>
                                            </tr>
                                        </template>  
                                    </template>                                    

                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- end content main -->  

    <!-- Loading -->
    <Loader :isLoad="!datas" />

    <Footer />
</div>

</template>

<script>
import Header from '@/components/Layout/Header.vue'; 
import Footer from '@/components/Layout/Footer.vue'; 
import {isUserFrench} from '@/utils'
import Loader from "@/components/Layout/Loader";

export default {
    name: 'hta_roadmap',
    components: {        
        Header,
        Footer,
        Loader
    },
    watch : {
        $route(){
            this.$store.dispatch("hta_roadmap/setCountry", this.$route.params.country)
            if(!this.datas){
                this.$store.dispatch("hta_roadmap/get_datas", this.$route.params.country)
            }
        }
    },
    mounted(){
        this.$store.dispatch("hta_roadmap/get_datas", this.$route.params.country)
    },
    updated(){
        if(document.getElementById('table1')){
            document.getElementById('table1').addEventListener('click', function(event) {
                event.preventDefault();
                console.log('clicked: ', event.target, document.getElementById('DIV1').style.display);
                if(document.getElementById('DIV1').style.display.toLowerCase() == 'none'){
                    document.getElementById('DIV1').style.display = 'block'
                }
                else{
                    document.getElementById('DIV1').style.display = 'none'
                }
            })
        }
        if(document.getElementById('table2')){
            document.getElementById('table2').addEventListener('click', function(event) {
                event.preventDefault();
                console.log('clicked: ', event.target, document.getElementById('DIV2').style.display);
                if(document.getElementById('DIV2').style.display.toLowerCase() == 'none'){
                    document.getElementById('DIV2').style.display = 'block'
                }
                else{
                    document.getElementById('DIV2').style.display = 'none'
                }
            })
        }
        if(document.getElementById('table3')){
            document.getElementById('table3').addEventListener('click', function(event) {
                event.preventDefault();
                console.log('clicked: ', event.target, document.getElementById('DIV3').style.display);
                if(document.getElementById('DIV3').style.display.toLowerCase() == 'none'){
                    document.getElementById('DIV3').style.display = 'block'
                }
                else{
                    document.getElementById('DIV3').style.display = 'none'
                }
            })
        }
    },
    computed : {
        datas(){
            return this.$store.getters["hta_roadmap/datas"]
        },
        country(){
            return this.$store.getters["hta_roadmap/country"]
        },
        country_name(){
            return this.datas && this.datas.data && this.datas.data[0]["country"] ? " - " + this.datas.data[0]["country"]["country_name"] : "";
        }
    },
    methods : {
        isUserFrench,
        img(value){
            switch (value['url_fr']){
                case "early_access" :
                    return "early"
                case "drug_assessment" :
                    return "drug";
                case "pricing_negociation" :
                    return "pricing";
                case "access_schemes" :
                    return "access";
                case "appeals" :
                    return "appeals";
                case "pricing" :
                    return "pricing";
                case "HTA" :
                    return "early1";
                case "reimbursement" :
                    return "drug";
                case "pricing & reimbursement" :
                    return "pricing";
                default:
                    return value['url_fr'];
            }
        },
        class_(value){
            switch (value['url_fr']){
                case "early_access" :
                    return "early"
                case "drug_assessment" :
                    return "drug";
                case "pricing_negociation" :
                    return "pricing";
                case "access_schemes" :
                    return "access";
                case "appeals" :
                    return "appeals";
                case "pricing" :
                    return "pricing";
                case "HTA" :
                    return "HTA";
                case "reimbursement" :
                    return "reimbursement";
                case "pricing & reimbursement" :
                    return "reimbursement-price";   
                default:
                    return value['url_fr'];
            }
        },
        tab(value){
            switch (value['url_fr']){
                case "early_access" :
                    return "early"
                case "drug_assessment" :
                    return "drug";
                case "pricing_negociation" :
                    return "pricing";
                case "access_schemes" :
                    return "access";
                case "appeals" :
                    return "appeals";
                case "pricing" :
                    return "pricing";
                case "HTA" :
                    return "HTA";
                case "reimbursement" :
                    return "reimbursement";
                case "pricing & reimbursement" :
                    return "reimbursement-price";
                default:
                    return value['url_fr'];
            }
        },
        handleClickBoxSearch(){
            this.$store.dispatch("header/setOpenBoxSearch", !this.openBoxSearch)
        },
    }
    
}
</script>

<style src="../assets/css/health.css"></style>
<style src="../assets/css/health-custom.css"></style>
