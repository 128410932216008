<template>
<div>
    <Header /> 

    <!-- content main --> 
    <div class="main-body hta_roadmap" style="padding: 0px; min-height: calc(100vh - 130px); position: relative !important;">
        <div class="body-fiche w-100">
            <div class="wrapper-box">
                <div class="header-title" style="margin-bottom:0">
                    <div><img v-if="country_name" :src="require('@/assets/images/flag/new/'+$route.params.country+'.svg')" alt=""> {{country_name}}</div>
                    <div v-if="list_country">
                        <template v-for="(val, key) in listDatas" :key="key">
                            <router-link :to="'/time_to_access/'+val.country"  v-if="$route.params.country !== val.country"><img :src="require('@/assets/images/flag/new/'+val.country+'.svg')" alt=""> </router-link>
                        </template>                        
                    </div>
                </div>

                
                <div class="wrapper-table">
                    <template v-if="datas && datas.datas && country_name">
                        <table v-for="(value, key) in datas.datas" :key="'data-'+key">                                                  
                            <!-- <table v-for="(value_multi, key_multi) in value.content_multis" :key="'key_multi'+key_multi" > -->
                                 
                            <tr>
                                <td>
                                    <div style="width:1100px" >
                                        <div class="pull-right" style="padding-right: 22px;">
                                        <a :href="value.pathExcel" download class="btn btn-border-orange"><i class="fas fa-download"></i> Download</a>
                                        </div>
                                        <div>
                                            <img :src="value.pathSvg+'?'+todayDate" />
                                        </div>
                                    </div>                                            
                                </td>
                            </tr> 
                        </table>                       
                        
                    </template>
                </div>

            </div>
        </div>
    </div>
    <!-- end content main -->  


    <!-- Loading -->
    <Loader :isLoad="isLoad" />
    <Footer />
</div>
</template>


<script>

import Header from '@/components/Layout/Header.vue';
import Footer from '@/components/Layout/Footer.vue';
import Loader from "@/components/Layout/Loader";

import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router';
import moment from 'moment'
export default {
    components: {        
        Header,
        Footer,
        Loader
    },
    setup() {
        const store = useStore();
        const route = useRoute()
        const listDatas = [
            {
                country: "fr",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/France.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/France.xlsx"
                    }
                ]
            },
            {
                country: "de",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Germany.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Germany.xlsx"
                    }
                ]
            },            
            {
                country: "it",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Italy.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Italy.xlsx"
                    }
                ]
            },
            {
                country: "es",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Spain.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Spain.xlsx"
                    }
                ]
            },
            {
                country: "gb",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/England.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/England.xlsx"
                    },
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Scotland.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Scotland.xlsx"
                    },
                ]
            },
            {
                country: "ca",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Canada.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Canada.xlsx"
                    }
                ]
            },
            {
                country: "ie",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Ireland.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Ireland.xlsx"
                    }
                ]
            },
            {
                country: "jp",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Japan.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Japan.xlsx"
                    }
                ]
            },
            {
                country: "kr",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Korea.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Korea.xlsx"
                    }
                ]
            },
            {
                country: "nl",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Netherlands.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Netherlands.xlsx"
                    }
                ]
            },
            {
                country: "se",
                datas: [
                    {
                        pathSvg: "/tinymcefilemanager/time_to_access/Sweden.svg",
                        pathExcel: "/tinymcefilemanager/time_to_access/excel/Sweden.xlsx"
                    }
                ]
            },
            
        ];
        
        const isLoad = ref(false)
        const currentCountry = computed(() => route.params.country ) 
        const list_country = computed(() => store.state.agency.list_country); 
        const country_name = computed(() => {
            if(list_country.value.length){
                return list_country.value.filter((v) => v.icon_flags === currentCountry.value)[0]['country_name'];
            }
            return "";
        });
        watch(currentCountry, () => {
            country_name.value = "";
        })
        

        onMounted(() => {
            if(!list_country.value.length){
                let tempCodeCountry = [];
                listDatas.forEach((val) => {
                    tempCodeCountry.push(val.country);
                })
                isLoad.value = true;
                store.dispatch("agency/getCountryByIconFlag", {icon_flags: tempCodeCountry}).then(
                    () => {
                        isLoad.value = false   
                    }
                );
            }
        });
        
        const datas = computed(() => listDatas.filter((v) => v.country === currentCountry.value)[0] )
        const todayDate = moment().format("YYYY-MM-DD");

        
        
            
        return {country_name, list_country, listDatas, isLoad, datas, currentCountry, todayDate}
    }
}
</script>

<style src="../assets/css/health.css"></style>
<style src="../assets/css/health-custom.css"></style>